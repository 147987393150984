import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { Helpers } from '../core';
import { trackCustomMetric } from '../metrics/track-params';
import { formatMetricValue } from '../metrics/metrics-helpers';
import useUserData from '../hooks/useUserData';
import useSearchEndpoint from './useSearchEndpoint';
import { useHeaderSettings } from '../hooks/useHeaderFooter';

import useProductPrice from '../hooks/useProductPrice';
import useHawksearchClientData from './useHawksearchClientData';
import { constructRelativeSrpPath, parseSupportData, getInitialSearchObject, getSrpTypeFromUrl, addRecentSearch } from './util';
import useTestFlags from '../abtest/useTestFlags';

import { getRecentSearches, replaceRecentSearchesInLocalStorage } from './util';

const fetchAutocomplete = Helpers.memoize(
    (searchKey, clientData, params = { ProductCount: 9, Type: 'Product' }, hawksearchApi) =>
        hawksearchApi.autocomplete(searchKey, clientData, params),
    (searchKey, clientData, params) =>
        `${searchKey}${clientData ? '-' + JSON.stringify(clientData) : ''}${
            params ? '-' + JSON.stringify(params) : ''
        } `
);

export const fetchSupportData = Helpers.memoize(
    (supportApi, searchKey, numResults=3, page) => supportApi.typeahead(searchKey, numResults, page).then(parseSupportData),
    (supportApi, searchKey, numResults=3, page) => `${!!supportApi}-${searchKey}-${numResults}-${page}`
)

const debounceFunc = debounce(callback => callback(), 200);

export default ({ disableInteractivity, withSupport }) => {
    const [hawksearchApi] = useSearchEndpoint();
    const [supportApi] = useSearchEndpoint('support');
    const [autocompleteData, setAutocompleteData] = useState(null);
    const [autocompletePreviewData, setAutocompletePreviewData] = useState(null);
    const [autocompleteInputFocused, setAutocompleteInputFocused] = useState(false);
    const [autocompleteModalOpen, setAutocompleteModalOpen] = useState(false);
    const [mobileInputOpen, setMobileInputOpen] = useState(false);
    const [showTrendingKeywords, setShowTrendingKeywords] = useState(false); // after 1st click, and no search keyword provided
    const [showRecentSearches, setShowRecentSearches] = useState(false); // after 2nd click and no search keyword provided, or whenever there is a search keyword
    const [recentSearches, setRecentSearches] = useState([]);
    const [trendingKeywords, setTrendingKeywords] = useState(null);
    const { userData } = useUserData();
    const routerLocation = useSelector(state => state && state.router && state.router.location);
    const { autoCompleteProductResultCount } = useHeaderSettings();
    const autoCompleteParams =
        typeof autoCompleteProductResultCount === 'number' && autoCompleteProductResultCount > 0
            ? { Type: 'Product', ProductCount: autoCompleteProductResultCount }
            : undefined;
    const { pathname: currPathname, query, search } = routerLocation || {};
    const currKeyword = query && query.keyword;
    const { profileData } = userData || {};
    const { personData } = profileData || {};
    const { pStoreID } = personData || {};
    const initialSearchKey = useMemo(() => {
        try{
            if(/\/sitesearch/.test(currPathname) && /keyword\=/i.test(search)){
                let { Keyword } = getInitialSearchObject(search);
                return Keyword;
            }
        } catch (e) {}
    }, [])

    const [searchKey, setSearchKey] = useState(null);
    const history = useHistory();
    const clientData = useHawksearchClientData();

    const dispatch = useDispatch();
    const trackSearchAutoClick = useCallback((searchCategory, searchTerm, clickedTerm) => () => {
        dispatch(
            trackCustomMetric('searchAutoClick', {
                searchCategory: searchCategory.toLowerCase(),
                searchTerm: formatMetricValue(searchTerm),
                clickedTerm: formatMetricValue(clickedTerm),
            })
        );
    });

    const testFlags = useTestFlags(['autocomplete_trending_keywords']);
    const { autocomplete_trending_keywords } = testFlags || {};
    const { autocompleteTrendingKeywordsType } = autocomplete_trending_keywords || {};
    const autocompleteTrendingKeywordsTestEnabled = ['tertiaryButtons', 'quickFilters'].includes(autocompleteTrendingKeywordsType);
    const shouldRefreshRecentSearches = autocompleteTrendingKeywordsTestEnabled && autocompleteModalOpen;
    const setAutocompleteStateOnClick = useCallback(() => {
        if(!autocompleteTrendingKeywordsTestEnabled) {
            return;
        }

        setAutocompleteModalOpen(true);
        !showTrendingKeywords && setShowTrendingKeywords(true);
        !showRecentSearches && setShowRecentSearches(true);

    }, [autocompleteTrendingKeywordsTestEnabled, showTrendingKeywords, showRecentSearches])

    const hideTrendingKeywordsOnInputChange = useCallback( () => {
        showTrendingKeywords && setShowTrendingKeywords(false);
    }, [showTrendingKeywords]);

    useEffect(() => {
        if(autocompleteTrendingKeywordsTestEnabled && showTrendingKeywords && (!searchKey || searchKey.length === 0)){
            fetchAutocomplete('', clientData, autoCompleteParams, hawksearchApi).then( resp => {
                const { data } = resp || {};
                const { Popular } = data || {};
                setTrendingKeywords(Popular);
                setAutocompleteModalOpen(true)
            })
        }
    }, [showTrendingKeywords])

    // used to trigger a page naviagation when pressing enter and the autocomplete request has not yet finished.
    // the page will navigate once the autocomplete data is retrieved.
    const [queuedPageNavigation, setQueuedPageNavigation] = useState(false);

    const { searchKey: autocompleteSearchKey, Redirect } = autocompleteData || {};
    const redirectLocation = Redirect && Redirect.Location;

    let autocompletePreviewSelectData = autocompletePreviewData
        ? {
              Content: autocompletePreviewData.Content,
              ContentCount: autocompletePreviewData.ContentCount,
              ContentHeading: autocompletePreviewData.ContentHeading,
              Count: autocompletePreviewData.Count,
              ProductCount: autocompletePreviewData.ProductCount,
              ProductHeading: autocompletePreviewData.ProductHeading,
              Products: autocompletePreviewData.Products,
              previewKeyword: autocompletePreviewData.previewKeyword,
              support: autocompletePreviewData.support,
              ...autocompletePreviewData.withSuggestions ? { Popular: autocompletePreviewData.Popular } : {},
              ...autocompletePreviewData.seeAllLinkKeyword ? { seeAllLinkKeyword: autocompletePreviewData.seeAllLinkKeyword } : {}
          }
        : null;

    const { Products } = autocompletePreviewSelectData || autocompleteData || {};

    const products = Array.isArray(Products) ? Products : [];

    const transformedProducts = products.reduce((allCatEntryIds, prd) => {
        try {
            allCatEntryIds.push({
                catentryId: prd.Results.Document.catentry_id[0],
                sku: prd.Results.Document.sku[0],
            });
        } catch (e) {}

        return allCatEntryIds;
    }, []);
    const { prices } = useProductPrice(transformedProducts);
    const getAutocompleteData = async searchKey => {
        if (!searchKey) return null;
        let [autocompleteResp, supportResp] = await Promise.all([
            fetchAutocomplete(searchKey, clientData, autoCompleteParams, hawksearchApi).catch(e => null),
            withSupport && supportApi ? fetchSupportData(supportApi, searchKey).catch(e => null) : null
        ]);
        let { data } = autocompleteResp || {};

        if (!data && !supportResp) {
            setAutocompleteData(null);
        }

        let { Products, Content } = data || {};

        setAutocompleteData({
            ...data || {},
            searchKey,
            noMatchingSearchKey: (!Products || Products.length === 0) && (!Content || Content.length === 0) && (!supportResp || supportResp.length === 0) ? searchKey : null,
            support: supportResp
        });

        if(searchKey && trendingKeywords){
            setTrendingKeywords(null);
        }
    };

    const onLinkHover = useCallback(
        (keyword, replaceLinks) =>
            Promise.all([
                fetchAutocomplete(keyword || '', clientData, autoCompleteParams, hawksearchApi),
                withSupport  && supportApi ? fetchSupportData(supportApi, keyword || '') : null
            ]).then(([resp, supportResp]) => {
                let { data } = resp || {};
                data.previewKeyword = keyword;
                data.support = supportResp;
                data.seeAllLinkKeyword = replaceLinks ? keyword : null;
                setAutocompletePreviewData(data);
            }),
        [autoCompleteProductResultCount, !!supportApi]
    );

    const hoveredKeyword = useRef(null);

    const debouncedSearchHoveredKeyword = useCallback(debounce((keyword, replaceLinks) => {
        hoveredKeyword && hoveredKeyword.current === keyword && onLinkHover(keyword, replaceLinks);
    }, 150), [!!hoveredKeyword]);
    
    const onLinkHoverDelayedSearch = useCallback( (keyword, replaceLinks) => {
        if(hoveredKeyword){
            hoveredKeyword.current = keyword;
            debouncedSearchHoveredKeyword(keyword, replaceLinks);
        }
    }, []);

    const onLinkHoveredAwayDelayedSearch = useCallback(() => {
        try{
            hoveredKeyword.current = null;
        }catch(e){}
    }, [])

    const onLinkHoverAway = useCallback(() => setAutocompletePreviewData(null), []);

    const resetAutocompletePreviewData = useCallback(() => {
        autocompletePreviewData && (!autocompleteTrendingKeywordsTestEnabled || (searchKey && searchKey.length > 0)) && setAutocompletePreviewData(null);
    }, [!!autocompletePreviewData])

    const setSearchKeyword = useCallback(value => {
        setSearchKey(value);
        !autocompleteModalOpen && setAutocompleteModalOpen(true);
    }, [autocompleteModalOpen]);

    const closeModalAndInputBar = useCallback(() => {
        setAutocompleteModalOpen(false);
        setAutocompleteInputFocused(false);
        setMobileInputOpen(false);
        if(autocompleteTrendingKeywordsTestEnabled){
            setShowTrendingKeywords(false);
            setShowRecentSearches(false);
        }
    }, [autocompleteTrendingKeywordsTestEnabled]);

    const getAutocompleteLinkData = useCallback((searchKey, pStoreID, seeAllLinkKeyword) => {
        const encodedSearchKey = encodeURIComponent(seeAllLinkKeyword ? seeAllLinkKeyword : searchKey);
        const hasKeyword = encodedSearchKey.length > 0;
        const keywordQs = hasKeyword ? `keyword=${encodedSearchKey}` : '';
        const pStoreIdQs = pStoreID && pStoreID.length > 0 ? 'pStoreID=' + pStoreID : '';
        const productSearch = `${keywordQs}${hasKeyword && pStoreIdQs.length > 0 ? '&' : ''}${pStoreIdQs}`;
        const contentSearch = `${keywordQs}${hasKeyword ? '&' : ''}type=content${pStoreIdQs.length > 0 ? '&' : ''}${pStoreIdQs}`;
        const supportSearch = `${keywordQs}${hasKeyword ? '&' : ''}type=support${pStoreIdQs.length > 0 ? '&' : ''}${pStoreIdQs}`;
        const productSrpLink = constructRelativeSrpPath(productSearch);
        const contentSrpLink = constructRelativeSrpPath(contentSearch);
        const supportSrpLink = constructRelativeSrpPath(supportSearch);

        return {
            encodedSearchKey,
            productSearch,
            contentSearch,
            supportSearch,
            productSrpLink,
            contentSrpLink,
            supportSrpLink,
        };
    }, []);

    const getAutocompleteProductLink = useCallback( searchKey => {
        const encodedSearchKey = encodeURIComponent(searchKey);
        const hasKeyword = encodedSearchKey.length > 0;
        const keywordQs = hasKeyword ? `keyword=${encodedSearchKey}` : '';
        const pStoreIdQs = pStoreID && pStoreID.length > 0 ? 'pStoreID=' + pStoreID : '';
        return constructRelativeSrpPath(`${keywordQs}${hasKeyword && pStoreIdQs.length > 0 ? '&' : ''}${pStoreIdQs}`);
    }, [pStoreID]);

    const navigatePage = type => {
        // if autocompleData.searchKey does not match searchKey. This must mean there's a pending search.
        // in that case, set queuedPageNavigation to true to trigger the navigation in the effect once the search completes.
        if (!autocompleteData || autocompleteData.searchKey !== searchKey) {
            setQueuedPageNavigation(true);
            return;
        }

        if (redirectLocation) {
            window.location.href = redirectLocation;
            return;
        }
        let product = Products
            ? Products.length === 1
                ? Products[0] // if only 1 product in result, use that. Otherwise, product with sku matching searchKey
                : Products.find(prd => {
                      return prd && typeof prd.Sku === 'string' && prd.Sku.toLowerCase() === searchKey.toLowerCase();
                  })
            : null;

        let pdpUrl =
            product &&
            product.Results &&
            product.Results.Document &&
            product.Results.Document.pdp_url &&
            product.Results.Document.pdp_url[0];

        if (pdpUrl) {
            let relativeIndex = pdpUrl.indexOf(process.env.BASENAME);
            let relativeLink = pdpUrl.substring(relativeIndex);
            history.push(relativeLink);
        } else {
            const currSrpType = getSrpTypeFromUrl();
            const pathname = `${process.env.BASENAME}/sitesearch`;
            const { encodedSearchKey, productSearch, contentSearch, supportSearch } = getAutocompleteLinkData(searchKey, pStoreID) || {};

            const nextSearch = ({
                'content': contentSearch,
                'support': supportSearch
            })[currSrpType] || productSearch;

            const pushState = {
                pathname,
                search: nextSearch,
            };
            const onSearchPage = typeof currPathname === 'string' && new RegExp('^' + pathname).test(currPathname);

            let currSearch;
            try {
                currSearch = window.location.search;
            } catch (e) {}

            onSearchPage &&
                currKeyword === encodedSearchKey &&
                (pushState.state = {
                    searchKey: encodedSearchKey,
                    searchOverride: `?${nextSearch}`,
                    prevSearch: currSearch,
                });
            autocompleteTrendingKeywordsTestEnabled && addRecentSearch(searchKey);
            history.push(pushState);
        }

        queuedPageNavigation && setQueuedPageNavigation(false);
        closeModalAndInputBar();
    };

    useEffect(() => {
        if(shouldRefreshRecentSearches){
            try{
                const recentSearchData = getRecentSearches().reduce((allRs, rs) => {
                    if(typeof rs === 'string' && rs.trim().length > 0){
                        allRs.push({
                            searchKey: rs,
                            link: getAutocompleteProductLink(rs)
                        })
                    }
    
                    return allRs;
                }, [])
    
                setRecentSearches(recentSearchData);
            }catch(e){}
        }
    }, [shouldRefreshRecentSearches, pStoreID])

    const removeRecentSearch = searchKey => {
        try{
            const newRecentSearches = recentSearches.slice();
            const idx = newRecentSearches.findIndex(rs => rs && rs.searchKey === searchKey);
            idx > -1 && newRecentSearches.splice(idx, 1);
            setRecentSearches(newRecentSearches);
            replaceRecentSearchesInLocalStorage(newRecentSearches.map(rs => rs && rs.searchKey));
        }catch(e){}
    };

    useEffect(() => {
        debounceFunc(() => getAutocompleteData(searchKey));
    }, [searchKey]);

    const autocompleteWithBreadcrumb = useSelector(state => {
        try{
            return  state.slugInfo.vanityUrl === 'sitesearch' && state.ui.width && state.ui.width < 361 && state.ui.customBreakpoints.unifiedSearch === 'mobile';
        }catch(e){}

        return false;
    })

    const resetAutocompleteState = useCallback(() => {
        closeModalAndInputBar();
        setAutocompletePreviewData(null);
        setAutocompleteData(null);
    }, [])

    // Add event listener only if isInteractive is set to true. Pressing enter if the input is focused and the search is a SKU should go to a pdp
    useEffect(() => {
        const onEnter = event => {
            if (event.key !== 'Enter' || !event || !event.target || event.target.id !== 'globalSearch') return;
            // if autocomplete data is empty or not matching current searchKey, delay page navigation until data has been retrieved.
            if (autocompleteSearchKey !== searchKey) {
                return setQueuedPageNavigation(true);
            }
            navigatePage();
        };

        if (autocompleteInputFocused && !disableInteractivity) {
            document.addEventListener('keydown', onEnter);
        }

        return () => {
            if (!disableInteractivity) {
                document.removeEventListener('keydown', onEnter);
            }
        };
    }, [autocompleteInputFocused, searchKey, autocompleteSearchKey, Products, redirectLocation]);

    useEffect(() => {
        if (queuedPageNavigation && searchKey && searchKey === autocompleteSearchKey) {
            navigatePage();
        }
    }, [searchKey, autocompleteSearchKey, queuedPageNavigation]);

    let singleProductUrl, singleProductPdpUrl;

    try{
        singleProductPdpUrl = Products[0].Results.Document.pdp_url[0];
    }catch(e){}

    try {
        let hasSingleProductResult = Products && Products.length === 1;
        let urlToEvaluate = hasSingleProductResult && (
            typeof singleProductPdpUrl === 'string' && singleProductPdpUrl.length > 0
            ? singleProductPdpUrl
            : typeof Products[0].Url === 'string' && Products[0].Url.length > 0 
            ? Products[0].Url
            : null
        )
        let basenameStart =
            urlToEvaluate && urlToEvaluate.toLowerCase().indexOf(process.env.BASENAME);
        singleProductUrl =
            typeof basenameStart === 'number' && basenameStart >= 0 && urlToEvaluate.substring(basenameStart);
    } catch (e) {}

    const navigateSrp = link => {
        try{
            history.push(link);
            closeModalAndInputBar();
        }catch(e){}
    };

    return {
        ...autocompleteData,
        ...(autocompletePreviewSelectData || {}),
        setAutocompletePreviewData,
        onLinkHover,
        onLinkHoverAway,
        prices,
        autocompleteInputFocused,
        setAutocompleteInputFocused,
        autocompleteModalOpen,
        setAutocompleteModalOpen,
        searchKey,
        setSearchKey,
        resetAutocompletePreviewData,
        setSearchKeyword,
        closeModalAndInputBar,
        setMobileInputOpen,
        mobileInputOpen,
        trackSearchAutoClick,
        redirectLocation,
        getAutocompleteLinkData,
        initialSearchKey,
        autocompleteWithBreadcrumb,
        autocompleteTrendingKeywordsType,
        setAutocompleteStateOnClick,
        showTrendingKeywords,
        showRecentSearches,
        trendingKeywords,
        getAutocompleteProductLink,
        autocompleteTrendingKeywordsTestEnabled,
        pStoreID,
        resetAutocompleteState,
        singleProductUrl,
        hideTrendingKeywordsOnInputChange,
        removeRecentSearch,
        recentSearches,
        onLinkHoverDelayedSearch,
        onLinkHoveredAwayDelayedSearch,
        navigateSrp
    };
};
